import styled, { css } from 'styled-components';

import { ANIMATION } from 'styles/variables';

import { IS_SELECTED_MESSAGE_CLASS, IS_UNREAD_MESSAGE_CLASS } from './constants';

export const RowShortStyled = styled.div`
  margin-bottom: 8px;
`;

const isRowShortClickableStyles = css`
  cursor: pointer;
`;

const isRowShortUnClickableStyles = css`
  cursor: default;
  pointer-events: none;
`;

export const RowShortContentStyled = styled.div<{
  clickable: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 8px;
  border-radius: 8px;
  background: var(--list-item-bg);
  color: var(--list-item-txt);
  box-shadow: 0 6px 20px 0 var(--list-item-shadow);
  transition:
    background ${ANIMATION},
    color ${ANIMATION};
  will-change: background, color;
  overflow: hidden;

  &.${IS_SELECTED_MESSAGE_CLASS} {
    background: var(--list-item-bg--selected);
    color: var(--list-item-txt--selected);
  }

  &.${IS_UNREAD_MESSAGE_CLASS} {
    background: var(--list-item-bg--unread);
    color: var(--list-item-txt--unread);
  }

  @media (hover: hover) {
    &:hover {
      background: var(--list-item-bg--hover);
      color: var(--list-item-txt--hover);
    }
  }

  ${({ clickable }) => (clickable ? isRowShortClickableStyles : isRowShortUnClickableStyles)}
`;

export const RowShortTopStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 8px;
  width: 100%;
  min-width: 0;
  overflow: hidden;
`;

export const RowShortTopDataStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 8px;
  flex: 1;
  overflow: hidden;
`;

export const RowShortTitleStyled = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RowShortDescStyled = styled.div`
  max-width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  .${IS_UNREAD_MESSAGE_CLASS} & {
    font-weight: 600;
  }
`;

export const RowShortAdditionalInfoStyled = styled.div`
  flex: 1;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.25;
  text-align: right;
  overflow: hidden;
`;
