import React, { FC, memo } from 'react';

// https://phosphoricons.com/

import { ICON_SIZE } from './constants';
import {
  BackspaceIcon,
  BuildingOfficeIcon,
  CaretDownIcon,
  CaretLeftIcon,
  CaretRightIcon,
  CaretUpIcon,
  ChartDonutIcon,
  ChartLineIcon,
  ChatTeardropIcon,
  CheckIcon,
  CheckCircleIcon,
  CheckCircleFillIcon,
  ClockCountdownIcon,
  ClockCounterClockwiseIcon,
  CloseIcon,
  CloseCircleIcon,
  CloseCircleFillIcon,
  DownloadIcon,
  GlobeIcon,
  IconProps,
  InfoIcon,
  InfoFillIcon,
  LightbulbIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  MinusCircleIcon,
  MinusCircleFillIcon,
  NotebookIcon,
  PaperPlaneIcon,
  PaperPlaneFillIcon,
  PaperclipIcon,
  PencilIcon,
  PlusIcon,
  PlusCircleIcon,
  PlusCircleFillIcon,
  QuestionIcon,
  QuestionFillIcon,
  SignOutIcon,
  TrashIcon,
  UploadIcon,
  UserIcon,
  UsersIcon,
  WarningCircleIcon,
  WarningCircleFillIcon,
} from './Icons';

export type IconName =
  | 'backspace'
  | 'buildingOffice'
  | 'caretDown'
  | 'caretLeft'
  | 'caretRight'
  | 'caretUp'
  | 'chartDonut'
  | 'chartLine'
  | 'chatTeardrop'
  | 'check'
  | 'checkCircle'
  | 'checkCircleFill'
  | 'clockCountdown'
  | 'clockCounterClockwise'
  | 'close'
  | 'closeCircle'
  | 'closeCircleFill'
  | 'download'
  | 'globe'
  | 'info'
  | 'infoFill'
  | 'lightbulb'
  | 'magnifyingGlass'
  | 'minus'
  | 'minusCircle'
  | 'minusCircleFill'
  | 'notebook'
  | 'paperPlane'
  | 'paperPlaneFill'
  | 'paperclip'
  | 'pencil'
  | 'plus'
  | 'plusCircle'
  | 'plusCircleFill'
  | 'question'
  | 'questionFill'
  | 'signOut'
  | 'trash'
  | 'upload'
  | 'user'
  | 'users'
  | 'warningCircle'
  | 'warningCircleFill';

interface Props {
  type: IconName;
}

const Icon: FC<Props & Partial<IconProps>> = ({
  type,
  width = ICON_SIZE,
  height = ICON_SIZE,
  ...props
}) => {
  const params = { width, height };

  return {
    backspace: <BackspaceIcon {...params} {...props} />,
    buildingOffice: <BuildingOfficeIcon {...params} {...props} />,
    caretDown: <CaretDownIcon {...params} {...props} />,
    caretLeft: <CaretLeftIcon {...params} {...props} />,
    caretRight: <CaretRightIcon {...params} {...props} />,
    caretUp: <CaretUpIcon {...params} {...props} />,
    chartDonut: <ChartDonutIcon {...params} {...props} />,
    chartLine: <ChartLineIcon {...params} {...props} />,
    chatTeardrop: <ChatTeardropIcon {...params} {...props} />,
    check: <CheckIcon {...params} {...props} />,
    checkCircle: <CheckCircleIcon {...params} {...props} />,
    checkCircleFill: <CheckCircleFillIcon {...params} {...props} />,
    clockCountdown: <ClockCountdownIcon {...params} {...props} />,
    clockCounterClockwise: <ClockCounterClockwiseIcon {...params} {...props} />,
    close: <CloseIcon {...params} {...props} />,
    closeCircle: <CloseCircleIcon {...params} {...props} />,
    closeCircleFill: <CloseCircleFillIcon {...params} {...props} />,
    download: <DownloadIcon {...params} {...props} />,
    globe: <GlobeIcon {...params} {...props} />,
    info: <InfoIcon {...params} {...props} />,
    infoFill: <InfoFillIcon {...params} {...props} />,
    lightbulb: <LightbulbIcon {...params} {...props} />,
    magnifyingGlass: <MagnifyingGlassIcon {...params} {...props} />,
    minus: <MinusIcon {...params} {...props} />,
    minusCircle: <MinusCircleIcon {...params} {...props} />,
    minusCircleFill: <MinusCircleFillIcon {...params} {...props} />,
    notebook: <NotebookIcon {...params} {...props} />,
    paperPlane: <PaperPlaneIcon {...params} {...props} />,
    paperPlaneFill: <PaperPlaneFillIcon {...params} {...props} />,
    paperclip: <PaperclipIcon {...params} {...props} />,
    pencil: <PencilIcon {...params} {...props} />,
    plus: <PlusIcon {...params} {...props} />,
    plusCircle: <PlusCircleIcon {...params} {...props} />,
    plusCircleFill: <PlusCircleFillIcon {...params} {...props} />,
    question: <QuestionIcon {...params} {...props} />,
    questionFill: <QuestionFillIcon {...params} {...props} />,
    signOut: <SignOutIcon {...params} {...props} />,
    trash: <TrashIcon {...params} {...props} />,
    upload: <UploadIcon {...params} {...props} />,
    user: <UserIcon {...params} {...props} />,
    users: <UsersIcon {...params} {...props} />,
    warningCircle: <WarningCircleIcon {...params} {...props} />,
    warningCircleFill: <WarningCircleFillIcon {...params} {...props} />,
  }[type];
};

export default memo(Icon);
