import React, { useEffect } from 'react';

import usePrefix from 'utils/usePrefix';
import { Violence } from 'utils/api';

import { ButtonSelect, ButtonSelectIcon, Label, GroupItem, GroupList } from './styles';

interface Props {
  type: Violence;
  onSelect: (id: number) => void;
  selectedId?: number;
  setCategoryId: (id: number | null) => void;
  categoryId: number | null;
}

const Category: React.FC<Props> = ({ type, onSelect, selectedId, setCategoryId, categoryId }) => {
  const t = usePrefix('Violence');

  const isSelected = categoryId === type.id;
  const isSingleLevel = !type.subcategories;

  useEffect(() => {
    if (selectedId && type.subcategories?.find((sub) => sub.id === selectedId))
      setCategoryId(type.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ButtonSelect
        selected={isSingleLevel && type.id === selectedId}
        type="button"
        onClick={() =>
          !isSingleLevel ? setCategoryId(isSelected ? null : type.id) : onSelect(type.id)
        }
        marginBot="8px"
      >
        <Label>
          <span>{t(`${type.key}`)}</span>
        </Label>
        <ButtonSelectIcon type={isSingleLevel ? 'plus' : 'caretDown'} width="16" height="16" />
      </ButtonSelect>
      {!isSingleLevel && isSelected && (
        <GroupList>
          {type.subcategories?.map((sub) => (
            <GroupItem key={sub.id}>
              <ButtonSelect
                type="button"
                title={t(`${sub.key}`)}
                onClick={() => onSelect(sub.id)}
                selected={selectedId === sub.id}
              >
                <Label>
                  <span>{t(`${sub.key}`)}</span>
                </Label>
                <ButtonSelectIcon type="plus" width="16" height="16" />
              </ButtonSelect>
            </GroupItem>
          ))}
        </GroupList>
      )}
    </>
  );
};

export default Category;
