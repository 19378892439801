import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';
import {
  passwordMinLength,
  upperRegex,
  lowerRegex,
  numberRegex,
  specialCharRegex,
} from 'utils/validation';

import Button from 'components/_Redesign/Button';
import {
  AccessPageForm,
  AccessPageFormActionsItemStyled,
  AccessPageFormActionsStyled,
} from 'components/AccessPage/AccessPageForm/styles';
import FormFieldGroup from 'components/_Redesign/FormFieldGroup';

import { RequirementsListStyled } from './styles';
import RequirementItem from './RequirementItem';
import { FormValues } from '.';

const Form: React.FC<
  Pick<FormikProps<FormValues>, 'errors' | 'touched' | 'isSubmitting' | 'values'>
> = ({ errors, touched, isSubmitting, values, ...props }) => {
  const t = usePrefix('Login');

  return (
    <AccessPageForm {...props} autoComplete="off">
      <FormFieldGroup
        name="password"
        error={touched.password && !!errors.password}
        label={t('new_password')}
        type={'password'}
        placeholder={'••••••••••'}
      />
      <FormFieldGroup
        name="password_verify"
        error={touched.password_verify && errors.password_verify}
        label={t('confirm_password')}
        type={'password'}
        placeholder={'••••••••••'}
      />

      <AccessPageFormActionsStyled>
        <AccessPageFormActionsItemStyled>
          <Button
            color="primary"
            shape="full"
            size="lg"
            label={t('save_password')}
            type="submit"
            isDisabled={isSubmitting}
          />
        </AccessPageFormActionsItemStyled>
      </AccessPageFormActionsStyled>

      <RequirementsListStyled role="group">
        <h3>{t('password_required')}</h3>
        <RequirementItem
          touched={!!values.password}
          error={values.password.length < passwordMinLength}
        >
          {t('password_chars')}
        </RequirementItem>
        <RequirementItem touched={!!values.password} error={!upperRegex.test(values.password)}>
          {t('password_upper')}
        </RequirementItem>
        <RequirementItem touched={!!values.password} error={!lowerRegex.test(values.password)}>
          {t('password_lower')}
        </RequirementItem>
        <RequirementItem touched={!!values.password} error={!numberRegex.test(values.password)}>
          {t('password_digit')}
        </RequirementItem>
        <RequirementItem
          touched={!!values.password}
          error={!specialCharRegex.test(values.password)}
        >
          {t('password_special_char')}
        </RequirementItem>
      </RequirementsListStyled>
    </AccessPageForm>
  );
};

export default Form;
