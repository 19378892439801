import styled, { css } from 'styled-components';

import FlexDiv from 'components/FlexDiv';
import DetailsTitle from 'components/DetailsTitle';

interface NoticeContentStyledProps {
  $isAttachments?: boolean;
}

const noticeAttachmentsStyles = css`
  grid-template-columns: repeat(2, 1fr);
`;

const noticeNoAttachmentsStyles = css`
  grid-template-columns: 1fr;
`;

export const NoticeContentStyled = styled.div<NoticeContentStyledProps>`
  ${({ $isAttachments }) => ($isAttachments ? noticeAttachmentsStyles : noticeNoAttachmentsStyles)};
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;

export const NoticeDetailsStyled = styled.div`
  padding: 20px;
  min-width: 0;
  height: 100%;
  overflow-y: auto;
`;

export const NoticeAttachmentsStyled = styled.div`
  padding: 20px;
  border-left: 1px solid var(--notice-border);
  min-width: 0;
  height: 100%;
  overflow-y: auto;
`;

const subtitleEmptyStyles = css`
  opacity: 0.2;
`;

export const Subtitle = styled.h3<{ empty?: boolean }>`
  margin: 0;
  font-size: 16px;
  color: var(--notice-txt--secondary);
  ${({ empty }) => empty && subtitleEmptyStyles};
`;

export const Title = styled(DetailsTitle)`
  font-size: 35px;
`;

export const Content = styled(FlexDiv)`
  width: 100%;
`;
