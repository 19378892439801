import React from 'react';
import { FormikProps } from 'formik';

import usePrefix from 'utils/usePrefix';

import {
  AccessPageForm,
  AccessPageFormActionsItemStyled,
  AccessPageFormActionsStyled,
} from 'components/AccessPage/AccessPageForm/styles';
import Button from 'components/_Redesign/Button';
import FormFieldGroup from 'components/_Redesign/FormFieldGroup';

export interface UserLoginData {
  email: string;
  password: string;
}

const Form: React.FC<Pick<FormikProps<UserLoginData>, 'errors' | 'touched' | 'isSubmitting'>> = ({
  errors,
  touched,
  isSubmitting,
  ...props
}) => {
  const t = usePrefix('Login');

  return (
    <>
      <AccessPageForm {...props} autoComplete="off">
        <FormFieldGroup
          name="email"
          error={touched.email && errors.email}
          label={t('email')}
          type="email"
          placeholder={t('email_placeholder')}
        />
        <FormFieldGroup
          name="password"
          type="password"
          error={touched.password && errors.password}
          label={t('password')}
          placeholder="••••••••••"
        />
        <AccessPageFormActionsStyled>
          <AccessPageFormActionsItemStyled>
            <Button
              color="primary"
              shape="full"
              size="lg"
              label={t('logIn')}
              type="submit"
              isDisabled={isSubmitting}
            />
          </AccessPageFormActionsItemStyled>
        </AccessPageFormActionsStyled>
      </AccessPageForm>
    </>
  );
};

export default Form;
