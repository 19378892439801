import styled, { css } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

import { screenLgUnder } from 'styles/breakpoinst';
import { ANIMATION } from 'styles/variables';
import Icon from 'components/Icons';

import { IS_ACTIVE_CLASS } from './constants';

export const NavigationStyled = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 40px;
  grid-area: nav;
  padding: 24px 16px;
  border-radius: 0 8px 8px 0;
  background: var(--navigation-bg);
`;

export const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;

  img {
    display: inline-flex;
    max-width: 100%;
    vertical-align: middle;
    object-fit: contain;
  }
`;

export const StyledIcon = styled(Icon)<{ margin?: string }>`
  margin-right: 4px;
  path {
    fill: currentColor;
    transition: fill ${ANIMATION};
  }
  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}
`;

export const buttonStyles = css`
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  column-gap: 8px;
  padding: 12px 8px;
  width: 100%;
  height: initial;
  border-radius: 8px;
  background: var(--navigation-item-bg);
  color: var(--navigation-item-txt);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  box-shadow: none;
  transition: color ${ANIMATION};

  @media (hover: hover) {
    &:hover {
      background: var(--navigation-item-bg--hover);
      color: var(--navigation-item-txt--hover);
    }
  }

  &.${IS_ACTIVE_CLASS} {
    background: var(--navigation-item-bg--active);
    color: var(--navigation-item-txt--active);
    pointer-events: none;
    opacity: 1;
  }

  ${StyledIcon} {
    flex: 0 0 24px;
  }

  @media screen and (max-width: ${screenLgUnder}) {
    & > span {
      display: none;
    }
  }
`;

interface NavigationMenuStyledProps {
  $isBottomAlign?: boolean;
}

export const NavigationMenuStyled = styled.ul<NavigationMenuStyledProps>`
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  ${({ $isBottomAlign }) => ($isBottomAlign ? 'margin-top: auto' : '')};
`;

export const NavigationItemStyled = styled.li`
  min-width: 0;
  width: 100%;
`;

export const NavItem = styled(NavLink)`
  ${buttonStyles};
`;

export const NavButton = styled.button`
  ${buttonStyles};
`;

export const SeparatorStyled = styled.hr`
  margin: 4px auto;
  width: 100%;
  max-width: 120px;
  height: 1px;
  background: var(--navigation-line);
`;
