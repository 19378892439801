/* eslint-disable no-extra-parens */
import React, { InputHTMLAttributes } from 'react';
import { FieldProps, Field } from 'formik';

import FormField from 'components/_Redesign/FormField';
import Input from 'components/_Redesign/Input';
import { FormFieldSize } from 'components/_Redesign/FormElements/types';

interface Props {
  name: string;
  placeholder?: string;
  label?: string;
  error?: boolean | string;
  type?: string;
  isEditing?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
  sizeField?: FormFieldSize;
}

const InputField: React.FC<Props & InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  placeholder,
  label,
  error,
  type = 'text',
  isEditing = true,
  disabled = false,
  autoFocus,
  sizeField = 'md',
  onFocus,
  ...props
}) => (
  <Field name={name}>
    {({ field }: FieldProps) => (
      <FormField error={error} label={label} {...props}>
        {isEditing ? (
          <Input
            placeholder={placeholder || ''}
            error={error}
            type={type}
            disabled={disabled}
            autoFocus={autoFocus}
            onFocus={onFocus}
            sizeField={sizeField}
            {...field}
            name={name}
            value={field.value || ''}
          />
        ) : (
          field.value
        )}
      </FormField>
    )}
  </Field>
);

export default InputField;
