import React, { memo } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Navigate } from 'react-router-dom';

import { api, getApiUrl, ResponseError } from 'utils/api';
import { PostUserLoginResponse } from 'utils/api/users';
import usePrefix from 'utils/usePrefix';
import { notificationTypes } from 'utils/constants';
import { userRoles } from 'utils/userRoles';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';

import { AccessPageCardStyled, AccessPageStyled } from 'components/AccessPage/styles';
import AccessPageHeader from 'components/AccessPage/AccessPageHeader';
import {
  AccessPageFormActionsItemStyled,
  AccessPageFormActionsStyled,
} from 'components/AccessPage/AccessPageForm/styles';
import { THEME } from 'styles/theme';

import Form from './Form';
import ForgotPasswordButton from './ForgotPasswordButton';

const url = getApiUrl('/users/login');

const validationSchema = (ty: (key: string, vars?: object) => string) =>
  Yup.object().shape({
    email: Yup.string().email(ty('invalid_email')).required(ty('field_required')),
    password: Yup.string().required(ty('field_required')),
  });

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const [{ profile }, dispatch] = useApp();

  const t = usePrefix('Login');
  const ty = usePrefix('YupErrors');

  const setDefaultBranding = () => {
    Object.entries(THEME).forEach(([key, value]) => {
      document.documentElement.style.setProperty(key, value);
    });
  };
  setDefaultBranding();

  const submit = async (values: FormValues) => {
    try {
      const response: PostUserLoginResponse = await api(url, {
        method: 'POST',
        payload: values,
      });

      if (response) {
        dispatch({ type: ActionTypes.LOGIN, payload: response });
        // await downloadFile();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  if (profile) {
    if (profile.role === userRoles.intervener) return <Navigate to="/app/chat" />;
    return <Navigate to="/app/reports" />;
  }

  return (
    <AccessPageStyled>
      <AccessPageCardStyled>
        <AccessPageHeader title={t('logging')} />
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={submit}
          validationSchema={() => validationSchema(ty)}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form errors={errors} touched={touched} isSubmitting={isSubmitting} />
          )}
        </Formik>
        <AccessPageFormActionsStyled>
          <AccessPageFormActionsItemStyled>
            <ForgotPasswordButton />
          </AccessPageFormActionsItemStyled>
        </AccessPageFormActionsStyled>
      </AccessPageCardStyled>
    </AccessPageStyled>
  );
};

export default memo(Login);
