/* eslint-disable no-magic-numbers */
import styled, { css } from 'styled-components';

import { calcSize } from 'styles/calcSize';

import { formFieldSizesFunc } from './sizes';
import { FormFieldSize } from './types';

export interface FormFieldStyledProps {
  $isError?: boolean | string;
  $sizeField?: FormFieldSize;
}

export const fieldErrorStyles = css`
  &:not(:focus-visible) {
    border-color: var(--form-field-border--error);
    color: var(--form-field-txt--error);
    background: var(--form-field-bg--error);
    &::placeholder {
      color: var(--form-field-txt--error);
    }
  }
`;

export const formFieldStyles = css<FormFieldStyledProps>`
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  border: 1px solid var(--form-field-border);
  background: var(--form-field-bg);
  color: var(--form-field-txt);
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  user-select: none;
  vertical-align: middle;
  box-shadow: 0 6px 20px 0 var(--form-field-shadow);
  appearance: none;
  ${formFieldSizesFunc};

  ${({ $isError }) => $isError && fieldErrorStyles};

  &::placeholder {
    color: var(--form-field-txt--placeholder);
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: var(--input-txt--disabled);
  }

  &:focus-visible {
    border-color: var(--form-field-border--focus);
  }

  &:autofill,
  &:-webkit-autofill {
    background: var(--form-field-bg);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
  }

  &::-webkit-date-and-time-value {
    text-align: left;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const labelStyles = css`
  display: block;
  font-weight: 600;
  font-size: ${calcSize(10)};
  line-height: 1.2;
  color: var(--form-label-txt);
  text-align: left;
`;

export const LabelStyled = styled('label')`
  ${labelStyles};
`;

export const fieldHintStyles = css`
  display: block;
  height: calc(${calcSize(10)} * 1.2);
  font-weight: 400;
  font-size: ${calcSize(10)};
  line-height: 1.2;
  color: var(--form-label-txt--error);
  text-align: left;
`;

export const FieldHintStyled = styled('div')`
  ${fieldHintStyles};
`;

export const FormItemStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  justify-content: flex-start;
  align-items: flex-start;
`;
