import React, { useState, useEffect, memo } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { GetUsersResponse } from 'utils/api/users';
import usePrefix from 'utils/usePrefix';
import { getApiUrl, api, PaginatedList, ResponseError } from 'utils/api';
import { useApp } from 'App';
import { ActionTypes } from 'App/types';
import { notificationTypes } from 'utils/constants';

import Loader from 'components/Loader';
import SearchBar from 'components/SearchBar';
import EmptyState from 'components/EmptyState';
import Row from 'components/Row';
import Modal from 'components/_Redesign/Modal';
import Button from 'components/_Redesign/Button';

import { Wrapper, IntervenersList, ActionStyled } from './styles';

const url = getApiUrl(`/schools`);

const RECORD_COUNT = 100;

interface Props {
  schoolId: number;
  isOpen: boolean;
  onClose: () => void;
  fetchData: () => void;
}

const PossibleUsersModal: React.FC<Props> = ({ schoolId, onClose, isOpen, fetchData }) => {
  const [, dispatch] = useApp();

  const t = usePrefix('Schools');
  const tg = usePrefix('General');

  const [users, setUsers] = useState<GetUsersResponse[]>([]);
  const [nameFilter, setNameFilter] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const MIN_QUERY_LENGTH = 3;

  const loadData = async () => {
    try {
      const response: PaginatedList<GetUsersResponse[]> = await api(
        `${url}/${schoolId}/possibleUsers?phrase=${nameFilter}&page=0&size=${RECORD_COUNT}`,
        {
          method: 'GET',
        },
      );

      if (response) {
        setUsers(response.content);
        setIsLoading(false);
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  useEffect(() => {
    if (nameFilter.length >= MIN_QUERY_LENGTH) {
      setIsLoading(true);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFilter]);

  const addUser = async (userId: number) => {
    try {
      const response = await api(`${url}/${schoolId}/users/${userId}`, {
        method: 'POST',
      });

      if (response) {
        dispatch({
          type: ActionTypes.SET_NOTIFICATION_CODE,
          payload: { type: notificationTypes.success },
        });
        fetchData();
        onClose();
      }
    } catch (error) {
      const typedError = error as ResponseError;
      dispatch({
        type: ActionTypes.SET_NOTIFICATION_CODE,
        payload: { code: typedError?.parsed?.code, type: notificationTypes.error },
      });
    }
  };

  return isOpen ? (
    <Modal onClose={onClose} size="lg" content="full">
      <Wrapper justifyContent="flex-start" alignItems="flex-start" flexDirection="column">
        <SearchBar
          name="name"
          placeholder={t('search')}
          onChange={(e) => setNameFilter(e.target.value)}
          value={nameFilter}
          onClear={() => setNameFilter('')}
          isDisabled={nameFilter.length < 1}
        />
        <IntervenersList>
          {users.length ? (
            <AutoSizer disableWidth>
              {({ height }: { height: number }) => (
                <List
                  height={height}
                  width="100%"
                  itemCount={users.length}
                  itemSize={48}
                  innerElementType="ul"
                >
                  {({ index, ...props }) => (
                    <Row
                      key={users[index].id}
                      title={`${users[index].first_name} ${users[index].last_name}`}
                      labels={[
                        `${users[index].first_name} ${users[index].last_name}`,
                        users[index].email,
                        tg(users[index].role),
                      ]}
                      clickable={false}
                      cellChildren={
                        <ActionStyled>
                          <Button
                            onClick={() => addUser(users[index].id)}
                            title={t('add')}
                            size="lg"
                            color="text-primary"
                            variant="text"
                            icon="plus"
                          />
                        </ActionStyled>
                      }
                      {...props}
                    />
                  )}
                </List>
              )}
            </AutoSizer>
          ) : (
            <EmptyState title={t('no_users')} />
          )}
        </IntervenersList>
        {isLoading && <Loader label={t('fetching_users')} />}
      </Wrapper>
    </Modal>
  ) : null;
};

export default memo(PossibleUsersModal);
