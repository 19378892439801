import styled from 'styled-components';
import { Form } from 'formik';

export const AccessPageForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-inline: auto;
  width: 100%;
  max-width: 336px;
`;

export const AccessPageFormActionsStyled = styled.div``;

export const AccessPageFormActionsItemStyled = styled.div`
  text-align: center;
`;
